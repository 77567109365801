"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store/index";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

axios.defaults.timeout = 120000; // ms
axios.defaults.retry = 4; // count
axios.defaults.retryDelay = 1000; // ms

export const _axios = axios.create(config);

// local서버
/* _axios.root = "http://localhost:"
_axios.port = "80"
_axios.imageRoot = "http://localhost:80"
_axios.preview = "http://localhost:80" */

// 쥬디님 서버
/* _axios.root = "http://test.crm.com:"
 _axios.port = "8102"
 _axios.imageRoot = "http://test.crm.com:8102"
 _axios.preview = "http://test.crm.com:"
 */
// stage 서버
/*  _axios.root = "http://stagecrm.carrotenglish.net:"
 _axios.port = "8102"
 _axios.imageRoot = "http://stagecrm.carrotenglish.net:8102"
 _axios.preview = "http://stagecrm.carrotenglish.net:" */

/*
 if (location.href.includes("stagecrm.carrotenglish.net")) { // 스테이지
  _axios.root = "http://stagecrm.carrotenglish.net:";
  _axios.port = "8102";
  _axios.imageRoot = "http://stagecrm.carrotenglish.net:8102"
 _axios.preview = "http://stagecrm.carrotenglish.net:8102";
}  else if (location.href.includes("happynetwork.info")) { // 운영

  _axios.root = "http://happynetwork.info:";
  _axios.port = "8102";
  _axios.imageRoot = "http://happynetwork.info:8102"
 _axios.preview = "http://happynetwork.info:8102";
  
} else if (location.href.includes('localhost')) { // 로컬
  _axios.root = "http://localhost:"
_axios.port = "8102"
_axios.imageRoot = "http://localhost:8102"
_axios.preview = "http://localhost:8102"
} else { // 로컬 다른사람들 테스트 용

  _axios.root = "http://test.crm.com:"
 _axios.port = "8102"
 _axios.imageRoot = "http://test.crm.com:8102"
 _axios.preview = "http://test.crm.com:8102"

}
*/

_axios.interceptors.request.use(
  function(config) {
    if (window.location.href.indexOf("stagecrm.happynetwork.info") > -1) {
      // 스테이지
      // config.baseURL = window.location.protocol + "//stagecrm.carrotenglish.net:8102";
      // config.port = 8012;
      // config.baseURL = window.location.protocol + "//3.37.145.43:30043";
      config.baseURL = window.location.protocol + "//balgum-crm.mindcafe.co.kr";
    } else if (window.location.href.indexOf("happynetwork.info") > -1) {
      // 운영
      // config.baseURL = window.location.protocol + "//happynetwork.info:20014";
      // config.baseURL = window.location.protocol + "//happynetwork.info";
      config.baseURL = window.location.protocol + "//balgum-crm.mindcafe.co.kr";
    } else if (window.location.href.indexOf("localhost") > -1) {
      // 로컬
      // config.baseURL = window.location.protocol + "//stagecrm.carrotenglish.net:8102";
      //config.baseURL = window.location.protocol+"//localhost:8102";
      config.baseURL = window.location.protocol + "//3.37.145.43:30043";
    } else {
      // 로컬 다른사람들 테스트 용
      // config.baseURL = window.location.protocol + "//test.crm.com:";
      // config.baseURL = window.location.protocol + "//3.37.145.43:30043";
      // config.baseURL = window.location.protocol + "//13.209.48.197:20014";
      config.baseURL = window.location.protocol + "//balgum-crm.mindcafe.co.kr";
    }

    config.headers.post["Content-Type"] = "application/json";
    config.headers.put["Content-Type"] = "application/json";
    config.withCredentials = false;
    config.headers.common["accessToken"] = store.state.userInfo.accessToken;

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    var config = error.config;
    console.error("Network Error, Retry", config);
    if (error.response.data.path == "/login/msg") {
      alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
      window.location.href = "/login";
    } else {
      // If config does not exist or the retry option is not set, reject
      if (!config || !config.retry) {
        console.log("config.retry Not Exist");
        return Promise.reject(error);
      }

      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0;

      // Check if we've maxed out the total number of retries
      if (config.__retryCount >= config.retry) {
        // Reject with the error
        console.log("Retry Count Overflow");
        return Promise.reject(error);
      }

      // Increase the retry count
      config.__retryCount += 1;

      // Create new promise to handle exponential backoff
      var backoff = new Promise(function(resolve) {
        console.log("Create New Promise");
        setTimeout(function() {
          resolve();
        }, config.retryDelay);
      });

      // Return the promise in which recalls axios to retry the request
      return backoff.then(function() {
        console.log("Recalls Axios to Retry");
        return _axios(config);
      });
    }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
